<template>
  <div class="about home">
    <h1>This is an Fav page</h1>
    <button @click="back">Back</button>
  </div>
</template>
<script>
export default {
  
 methods: {
   back(){
     history.go(-1)
   }
 },
}
</script>